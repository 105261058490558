import React from "react";
import clsx from "clsx";
import CircularProgress from "@material-ui/core/CircularProgress";
import { makeStyles } from "@material-ui/styles";

const useStyles = makeStyles(
  theme => ({
    root: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      padding: theme.spacing(4),
    },
    disablePadding: {
      padding: 0,
    },
  }),
  { name: "N0Spinner" },
);

function Spinner({ color = "secondary", disablePadding = false }) {
  const classes = useStyles();

  return (
    <div
      className={clsx(classes.root, {
        [classes.disablePadding]: disablePadding,
      })}>
      <CircularProgress color={color} size={24} />
    </div>
  );
}
export default Spinner;
